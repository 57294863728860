.billing-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: #f3f5fc;
}





.billing-card h2 {
  font-size: 24px;
  color: #333;
}

.billing-card p {
  margin: 5px 0;
  font-size: 16px;
  color: #555;
}

.paypal-section {
  margin-top: 20px;
}



.cancel-notice {
  margin-top: 20px;
  font-size: 16px;
  color: #777;
  text-align: center;
}

.paypal-section {
  margin-top: 20px;
}

.popup-message {
  margin-top: 20px;
  color: #2c8f2c; /* Green color */
  font-size: 16px;
  text-align: center;
  font-weight: bold;
}

