.signup-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0;
  max-width: 1200px;
  margin: 0 auto;
}

.signup-content {
  flex: 1;
  max-width: 800px;
  width: 100%;
  margin-top: 0;
  padding-top: 0;
}

.signup-h2 {
  margin: 0;
  font-size: 48px;
  font-weight: 900;
  margin: 120px 20px 20px 20px;
  text-align: center;
  font-family: 'Questerial', sans-serif; /* Updated font */

}

.signup-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 400px;
  margin: 0 auto;
}

input {
  margin-top: 0px; 
  
}

.email-input {
  margin-top: 0px;
}

.signup-form input {
  padding: 12px;
  border: 1px solid #292929;
  color: #000;
  border-radius: 10px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  line-height: 1.5;
  text-align: center;
  margin-top: 0;
}

.signup-form input::placeholder {
  text-align: center;
}

.signup-form button {
  width: 100%;
  padding: 12px 0;
  background-color: #267dff;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  font-family: 'Epilogue', sans-serif;
}

.user-type-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.user-type-option {
  margin-top: 1px;
  flex: 1;
  text-align: center;
  cursor: pointer;
  padding: 13px 20px;
  font-size: 16px;
  border: 5px solid #b6bcc5;
  background-color: #b6bcc5;
  color: black;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-weight: 600;
}

.user-type-option:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.user-type-option:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.user-type-option.selected {
  background-color: white;
  color: black;
}

.success-message {
  margin-top: 20px;
  font-size: 18px;
  color: black;
  text-align: center;
}

.forgot-password-text {
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 0;
}

.forgot-password-button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 14px;
}

.forgot-password-button:hover {
  background: none; /* Remove the background on hover */
  color: #0056b3; /* Optional: Add a hover color change to enhance UX */
}

.toggle-text {
  font-size: 14px;
  margin-top: 15px;
}

.reset-success-text {
  color: green;
  font-size: 0.9rem;
  margin-top: 5px;
}




.toggle-button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 14px;
}

.toggle-button:hover {
  background: none; /* Remove the background on hover */
  color: #0056b3; /* Optional: Add a hover color change to enhance UX */
}

.reset-password-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

.reset-email-input {
  padding: 12px;
  border: 1px solid #292929;
  border-radius: 10px;
  font-size: 16px;
  width: 120%;
  box-sizing: border-box;
  color: #000;
  text-align: center;
  line-height: 1.5;
  margin-bottom: 10px;
}

.reset-email-input::placeholder {
  text-align: center;
}

.reset-password-button {
  width: 70%;
  padding: 12px 0;
  background-color: #267dff;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  font-family: 'Epilogue', sans-serif;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.reset-password-button:hover {
  background-color: #0056b3;
}

.cancel-reset-button {
  width: 70%;
  padding: 8px 0;
  background-color: #ffffff;
  color: #333;
  border: 2px solid #cacaca;
  border-radius: 10px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  font-family: 'Epilogue', sans-serif;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.cancel-reset-button:hover {
  color: #000000;
}

.error-text {
  padding: 0;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it appears on top of other elements */
}

.signup-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0;
  max-width: 1200px;
  margin: 0 auto;
}

.signup-content {
  flex: 1;
  max-width: 800px;
  width: 100%;
  margin-top: 0;
  padding-top: 0;
}



.signup-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 400px;
  margin: 0 auto;
}

input {
  margin-top: 0px; 
  
}

.email-input {
  margin-top: 0px;
}

.signup-form input {
  padding: 12px;
  border: 1px solid #292929;
  color: #000;
  border-radius: 10px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  line-height: 1.5;
  text-align: center;
  margin-top: 0;
}

.signup-form input::placeholder {
  text-align: center;
}

.signup-form button {
  width: 100%;
  padding: 12px 0;
  background-color: #267dff;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  font-family: 'Epilogue', sans-serif;
}

.user-type-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.user-type-option {
  margin-top: 1px;
  flex: 1;
  text-align: center;
  cursor: pointer;
  padding: 13px 20px;
  font-size: 16px;
  border: 5px solid #b6bcc5;
  background-color: #b6bcc5;
  color: black;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-weight: 600;
}

.user-type-option:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.user-type-option:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.user-type-option.selected {
  background-color: white;
  color: black;
}

.success-message {
  margin-top: 20px;
  font-size: 18px;
  color: black;
  text-align: center;
}

.forgot-password-text {
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 0;
}

.forgot-password-button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 14px;
}

.forgot-password-button:hover {
  background: none; /* Remove the background on hover */
  color: #0056b3; /* Optional: Add a hover color change to enhance UX */
}

.toggle-text {
  font-size: 14px;
  margin-top: 15px;
}

.reset-success-text {
  color: green;
  font-size: 0.9rem;
  margin-top: 5px;
}




.toggle-button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 14px;
}

.toggle-button:hover {
  background: none; /* Remove the background on hover */
  color: #0056b3; /* Optional: Add a hover color change to enhance UX */
}

.reset-password-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

.reset-email-input {
  padding: 12px;
  border: 1px solid #292929;
  border-radius: 10px;
  font-size: 16px;
  width: 120%;
  box-sizing: border-box;
  color: #000;
  text-align: center;
  line-height: 1.5;
  margin-bottom: 10px;
}

.reset-email-input::placeholder {
  text-align: center;
}

.reset-password-button {
  width: 70%;
  padding: 12px 0;
  background-color: #267dff;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  font-family: 'Epilogue', sans-serif;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.reset-password-button:hover {
  background-color: #0056b3;
}

.cancel-reset-button {
  width: 70%;
  padding: 8px 0;
  background-color: #ffffff;
  color: #333;
  border: 2px solid #cacaca;
  border-radius: 10px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  font-family: 'Epilogue', sans-serif;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.cancel-reset-button:hover {
  color: #000000;
}

.error-text {
  padding: 0;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it appears on top of other elements */
}

/* Modal Box */
.modal {
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 80%;
  max-width: 600px;
  text-align: center;
  animation: fadeIn 0.3s ease; /* Add a subtle fade-in animation */
  
}

/* Modal Content */
.modal-content {
  max-height: 300px;
  overflow-y: auto; /* Add scrolling if content exceeds height */
  margin: 20px 0;
  text-align: left; /* Align text for better readability */
  font-size: 14px;
  line-height: 1.6;
  padding: 0; /* Add padding for better text spacing */
  word-wrap: break-word; /* Ensure long words or URLs wrap properly */
}
.modal-content h2,
.modal-content h3 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 10px;
}

.modal-content ul {
  margin: 10px 20px; /* Add margin for unordered lists */
}

.modal-content li {
  line-height: 1.8; /* Add more spacing for list items */
}



/* Modal Close Button */
.modal-close-button {
  background-color: #267dff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Epilogue', sans-serif;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.modal-close-button:hover {
  background-color: #0056b3;
  color: white;
}




.modal-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.modal-content h3 {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 400;
}

.modal-content h3 {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 400;
}


.modal-content p {
margin-bottom: 10px;
}

.checkbox-row {
display: flex;
align-items: center; /* Align checkbox and text vertically */
gap: 8px; /* Add spacing between checkbox and text */
margin-top: 5px;
justify-content: flex-start; /* Ensure they stay inline without stretching */
}

.checkbox-row input[type="checkbox"] {
margin: 0; /* Remove default margin for the checkbox */
width: 20px; /* Set a larger width */
height: 20px; /* Set a larger height */
cursor: pointer; /* Add a pointer cursor for better UX */
}


.checkbox-row span {
font-size: 14px;
line-height: 1.5;
cursor: pointer; /* Change cursor to pointer for better UX */
display: inline; /* Keep the label inline with the checkbox */
}


.link-text {
color: #007bff;
cursor: pointer;
text-decoration: underline;
margin-left: 4px; /* Space between "and" and the next link */
}

.link-text:hover {
color: #0056b3;
}

.error-text {
  color: red;
  font-size: 14px;
  margin-top: 10px; /* Space above the error text */
  text-align: center; /* Center-align the error message */
}





/* Fade-in Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}



/* Sign Up Page Responsive Styles */

/* Mobile view */
@media (max-width: 768px) {



  .signup-form input {
    padding: 10px; /* Reduce input padding */
    font-size: 14px; /* Reduce font size */
  }

  .signup-form button {
    padding: 8px 0;
    font-size: 16px; /* Reduce font size for button */
  }


  .user-type-option {
    padding: 10px; /* Reduce padding for user type options */
    font-size: 14px;
  }

  .toggle-text {
    font-size: 12px;
    margin-top: 10px;
  }

  .toggle-button {
    font-size: 12px;
  }

  .forgot-password-text  {
    font-size: 12px;
  }

  .forgot-password-button {
    font-size: 12px;
  }
}

/* Extra small mobile view */
@media (max-width: 480px) {
  .signup-h2 {
    font-size: 40px;
  }

  .signup-form {
    padding: 0;
    max-width: 260px;
  }

  .signup-form input {
    font-size: 12px;
    padding: 8px;
    margin-top: 0;
  }

  .signup-form button {
    font-size: 14px;
    padding: 6px 0;
  }

  .user-type-option {
    font-size: 12px;
    padding: 8px;
  }

}



/* Link Style for Terms and Privacy */
.link-text {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}

.link-text:hover {
  color: #0056b3; /* Darker color on hover for better UX */
}

/* Fade-in Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}



/* Sign Up Page Responsive Styles */

/* Mobile view */
@media (max-width: 768px) {



  .signup-form input {
    padding: 10px; /* Reduce input padding */
    font-size: 14px; /* Reduce font size */
  }

  .signup-form button {
    padding: 8px 0;
    font-size: 16px; /* Reduce font size for button */
  }


  .user-type-option {
    padding: 10px; /* Reduce padding for user type options */
    font-size: 14px;
  }

  .toggle-text {
    font-size: 12px;
    margin-top: 10px;
  }

  .toggle-button {
    font-size: 12px;
  }

  .forgot-password-text  {
    font-size: 12px;
  }

  .forgot-password-button {
    font-size: 12px;
  }
}

/* Extra small mobile view */
@media (max-width: 480px) {
  .signup-h2 {
    font-size: 40px;
  }

  .signup-form {
    padding: 0;
    max-width: 260px;
  }

  .signup-form input {
    font-size: 12px;
    padding: 8px;
    margin-top: 0;
  }

  .signup-form button {
    font-size: 14px;
    padding: 6px 0;
  }

  .user-type-option {
    font-size: 12px;
    padding: 8px;
  }

  .modal {
    width: 95%;
    max-width: 300px;
    padding: 15px;
  }

  .modal-content {
    font-size: 14px;
  }

  .modal-close-button {
    font-size: 12px;
    padding: 8px 16px;
  }
}