.emails-page {
  background-color: #f3f5fc;
  width: 100%;
  height: 210vh; /* Ensures full height of the viewport */
  margin: 0;
  font-family: 'Epilogue', sans-serif;
}

.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.root-container {
  min-height: 100%; /* Ensure it covers the content height */
  background-color: #f3f5fc; /* Background color for scrolling */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 0; /* Remove default margins */

}




.emails-container {
  display: flex;
  align-items: flex-start;
  max-width: 1100px;
  margin-top: 130px;
  border-radius: 8px;
  box-sizing: border-box;
  margin-right: 190px;
}

.youtuber-sidebar {
  min-width: 250px; /* Fixed width */
  max-width: 500px;  
  background-color: #ffffff;
  border-right: 1px solid #ddd;
  padding: 10px;
  text-align: center;
  border: 1px solid #ddd;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  transform: translateX(-86px);
  border-radius: 5px;

}

.youtuber-item {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s;
  border-radius: 5px;
}

.youtuber-item.active {
  background-color: #4285f4;
  color: #fff;
}

.brands-container {
  transform: translateX(-66px);
  min-width: 200px; /* Fixed width */
  max-width: 400px;  
  text-align: center;

  flex: 1;
}

.brand-list {
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.brand-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.brand-items {
  display: flex;
  flex-direction: column;
}

.brand-item {
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 3px;
}

.brand-item:hover,
.brand-item.selected {
  background-color: #4285f4;
  color: #fff;
}

.brand-items {
  list-style-type: none;
  margin: 0;
  padding: 0;
}


.brand-sidebar {
  min-width: 250px; /* Fixed width */
  max-width: 500px;
  background-color: #ffffff;
  padding: 10px 20px;
  margin-right: 20px;
  border-radius: 8px;
  transform: translateX(-86px);
  text-align: center;
  border: 1px solid #ddd;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

.brand-sidebar ul {
  list-style-type: none; /* No bullet points */
  padding: 0;
  margin: 0;
  font-size: 16px;
}

.brand-item {
  padding: 10px 0; /* Add spacing around each brand name */
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-bottom: 1px solid #ddd; /* Thin horizontal line */
  border-radius: 5px;
}

.brand-item:last-child {
  border-bottom: none; /* Remove line for the last item */
}

.brand-item:hover {
  background-color: #f0f0f0; /* Optional: Highlight on hover */
}

.brand-item:hover,
.brand-item.selected {
  background-color: #4285f4;
  color: #fff;
}

.email-card-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column; /* Ensure arrows and buttons are vertically stacked */
  position: relative;
  min-width: 800px;
}

.email-card {
  flex: 0 0 600px; /* Fixed width for the email card */
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  margin: 0;
  background-color: #ffffff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
}

.email-header {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
}

.email-section {
  margin-bottom: 25px;
  font-size: 16px;
}

.email-section label {
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 600;
}

.email-input-container {
  display: flex;
  align-items: center;
  width: 100%;
}

.email-textarea-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
  width: 100%;
}

.email-input,
.email-textarea {
  flex: 1;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  background-color: #fff;
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  box-sizing: border-box;
  margin-top: 5px;
}

.email-textarea {
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 1.6;
  color: #222222;
}

.email-textarea ul,
.email-textarea ol {
  padding-left: 20px;
  margin-bottom: 10px;
}

.email-textarea li {
  margin-bottom: 5px;
}

.email-textarea p {
  margin: 0 0 10px;
  font-size: 14px;
}

.email-textarea a {
  color: #4285f4;
  text-decoration: underline;
}

.email-textarea strong {
  font-weight: bold;
}

.email-textarea em {
  font-style: italic;
}

.email-textarea h1,
.email-textarea h2,
.email-textarea h3 {
  margin: 10px 0;
  font-weight: bold;
}

.month-year-group {
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 5px;
  font-size: 18px;
  cursor: pointer;
}

.channel-name {
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 5px;
  font-size: 18px;
  cursor: pointer;
}

.channel-name::after {
  display: inline-block;
  margin-left: 10px;
  transition: transform 0.3s;
}

.sent-group {
  background-color: rgba(72, 191, 72, 0.2); /* Green background with low opacity */
  padding: 8px;
  border-radius: 5px;
  font-weight: bold;
}

.not-sent-group {
  font-weight: bold;
  background-color: rgba(255, 99, 71, 0.2); /* Red background with low opacity */
  padding: 8px;
  border-radius: 5px;
}

.arrow {
  width: 40px;
  height: 40px;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.3s;
  position: absolute; /* Position arrows relative to the card-wrapper */
  top: 50%; /* Vertically align arrows with the card */
  transform: translateY(-50%);
}

.arrow:hover {
  opacity: 0.7;
}

.arrow-left {
  left: -50px; /* Position the left arrow to the left of the card */
  transform: translateX(-30px) rotate(180deg);
}

.arrow-right {
  right: -50px; /* Position the right arrow to the right of the card */
  transform: translateX(30px) rotate(0deg);
}

.email-buttons {
  display: flex;
  justify-content: center;
  width: 350px; /* Match the card's width */
  margin-top: 20px; /* Add spacing between the card and buttons */
}

.email-button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.email-button.send-all-emails:hover {
  background-color: #1663d7;
}

.send-email {
  background-color: #ffffff; /* Green for 'Send Email' */
  color: #333;
  border: #c4c4c4 solid 2px;
}

.send-email:hover {
  color: #595a5b;
  background-color: #ffffff;
}

.send-all-emails {
  background-color: #267dff; /* Yellow for 'Send All Emails' */
}

.send-all-emails:hover {
  background-color: #1663d7;
}

header {
  z-index: 10;
}

.no-emails-container {
  display: flex; /* Use Flexbox for alignment */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100%; /* Ensure it takes full height of its parent */
  text-align: center; /* Center-align text */
  margin-left: 190px;
  margin-top: 200px;
}
.sidebar-visible {
  display: block;
}

.dropdown-visible {
  display: none;
}

@media (min-width: 801px) and (max-width: 1350px) {
    .youtuber-sidebar {
      min-width: 200px; /* Reduce the minimum width */
      max-width: 250px; /* Set a reasonable maximum width */
      padding: 10px 15px; /* Adjust padding for smaller screens */
      transform: translateX(0); /* Ensure it stays within the frame */
      margin-right: 20px;
    }
  
    .youtuber-item {
      font-size: 14px; /* Reduce font size for smaller screens */
      padding: 8px; /* Reduce padding to fit more items */
      margin-bottom: 5px; /* Add spacing between items */
    }
  
    .emails-container {
      margin-right: 0; /* Prevent overflow caused by extra margin */
    }
  
    .email-card-wrapper {
      min-width: 500px;

    }

    .brands-container {
      transform: translateX(0); /* Ensure it stays within the frame */
      margin-right: 20px;


    }
  
  
}

@media (min-width: 1350px) {
  .youtuber-sidebar {
    transform: translateX(-60px);
  }

  .brands-container {
    transform: translateX(-40px);
  }

  .email-card-wrapper {
    transform: translateX(20px);
  }
}


@media (min-width: 1700px) {
  .youtuber-sidebar {
    transform: translateX(-186px);
  }

  .brands-container {
    transform: translateX(-166px);
  }

  .email-card-wrapper {
    transform: translateX(-106px);
  }
}



@media (max-width: 960px) {
  .brands-container {
    display: none; /* Hide the brands sidebar */
  }

  .emails-container {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .youtuber-brand-boxes {
    display: flex; /* Enable flex layout */
    justify-content: space-between; /* Add space between boxes */
    gap: 15px; /* Add spacing between the two boxes */
    width: 100%;
    max-width: 550px; /* Adjust maximum width for the container */
    margin: 20px 0;
    height: 70px;
    text-align: center;
  }

  .youtuber-box,
  .brand-box {
    flex: 1; /* Make both boxes equal width */
    max-width: 48%; /* Take up half of the available width with some gap */
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .box-placeholder {
    font-size: 16px;
    color: #3f3f3f;
    text-align: center; /* Align text within the box */
    width: 100%; /* Ensure the text spans the width of the box */
  }

  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Dimmed background */
    z-index: 1000;
  }

  .popup-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    padding: 20px;
    max-width: 90%; /* Ensure the popup width adjusts for smaller screens */
    max-height: 80vh; /* Limit height to 80% of the viewport */
    overflow-y: auto; /* Add vertical scroll if content exceeds height */
    width: 400px; /* Default width */
    text-align: center;
    z-index: 1001; /* Ensure it appears above the overlay */
  }

  .popup-modal h3 {
    margin-bottom: 20px;
    font-size: 20px;
    color: #333;
  }

  .popup-options {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 60vh; /* Ensure options list does not exceed 60% of the viewport height */
    overflow-y: auto; /* Add scrolling for long lists */
  }

  .popup-option {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f3f5fc;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .popup-option:hover {
    background-color: #4285f4;
    color: white;
  }

  .email-card-wrapper {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .email-card {
    width: 100%;
    max-width: 550px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  }

  .email-buttons {
    width: 100%;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
    text-align: center;
  }

  .arrow {
    display: none; /* Hide arrows on smaller screens */
  }

  .email-section {
    font-size: 14px;
  }

  .email-header {
    font-size: 18px;
    text-align: center;
  }
}

/* Responsive Styles for Half-Laptop Screens */
@media (min-width: 100px) and (max-width: 800px) and (min-height: 490px) {
  .emails-container {
    flex-direction: column;
    align-items: center;
    margin-right: 0; /* Remove extra margin */
    padding: 10px;
  }

  .youtuber-box,
  .brand-box {
    width: 100%;
    max-width: 550px;
    margin-bottom: 15px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .box-placeholder {
    font-size: 16px;
    color: #3f3f3f;
  }

  .email-buttons {
    max-width: 550px;
  }
}

/* Responsive Styles for Mobile Screens */
@media (max-width: 480px) {
  .emails-container {
    flex-direction: column;
    padding: 10px;
    margin: 100px 0 0 0;
  }

  .youtuber-box,
  .brand-box {
    width: 100%;
    max-width: 300px;
    margin-bottom: 10px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .box-placeholder {
    font-size: 14px;
    color: #3f3f3f;
  }
}

@media (max-width: 860px) {
  .email-card-wrapper {
    min-width: 0px;
    max-width: 1050px;
  }
}

@media (max-width: 550px) {
  .email-card-wrapper {
    min-width: 0px;
    max-width: 450px;
  }
}

@media (max-width: 430px) {
  .email-card-wrapper {
    min-width: 0px;
    max-width: 350px;
  }
}

@media (max-width: 380px) {
  .email-card-wrapper {
    min-width: 0px;
    max-width: 320px;
  }

  .youtuber-brand-boxes {
    margin-top: 50px;
    height: 60px;

  }
}

@media (max-width: 350px) {
  .email-card-wrapper {
    min-width: 0px;
    max-width: 300px;
  }

  .youtuber-brand-boxes {
    margin-top: 50px;
    height: 60px;

  }
}



@media (min-width: 600px) and (min-height: 550px) {

}