/* General container styling */
.purchase-emails-container {
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;
    padding: 50px 20px;
  }
  
  .purchase-emails-container h2 {
    font-size: 36px;
    margin-bottom: 40px;
    color: #333;
  }
  
  /* Influencers input */
  .influencers-input {
    margin-bottom: 20px;
  }
  
  .influencers-input label {
    font-size: 18px;
    color: #333;
  }
  
  .influencers-input input {
    width: 60px;
    padding: 8px;
    margin-left: 10px;
    font-size: 18px;
  }
  
  /* Flexbox layout for the pricing plans */
  .pricing-plans {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }
  
  .plan {
    background-color: #f9f9f9;
    border: 2px solid #ddd;
    border-radius: 12px;
    padding: 30px 20px;
    flex: 1;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .plan:hover {
    transform: translateY(-10px);
  }
  
  /* Plan headings */
  .plan h3 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
    font-weight: 600;
  }
  
  /* Key feature - Number of emails */
  .plan .emails {
    font-size: 36px;
    color: #000;
    margin: 20px;
    font-weight: 900;
  }
  
  /* Smaller font for the price */
  .plan .price {
    font-size: 20px;
    color: #666;
    margin-bottom: 20px;
    text-align: center;
  }
  
  /* Buy now button */
  .buy-now-button {
    background-color: #000;
    color: #fff;
    padding: 12px 24px;
    font-size: 18px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .buy-now-button:hover {
    background-color: #333;
  }
  
  /* Plan-specific colors */
  .plan.basic {
    border-color: #007bff;
  }
  
  .plan.standard {
    border-color: #28a745;
  }
  
  .plan.premium {
    border-color: #ffc107;
  }
  