/* Header container */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 20px 30px;
  box-sizing: border-box;
  z-index: 1000;
}

/* Logo styling */
.logo {
  font-size: 28px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  font-family: 'Questrial', sans-serif;
}

/* Desktop Navigation */
.nav-links {
  display: flex;
  align-items: center;
  gap: 100px;
  margin-left: 287px;
}

/* Individual button styling */
.header-button {
  font-size: 16px;
  background: none;
  border: none;
  color: #333;
  cursor: pointer;
  font-weight: 600;
  font-family: 'Epilogue', sans-serif;
}

/* Active button styling */
.header-button-active {
  color: #267dff;
}

/* Authentication buttons container */
.auth-buttons {
  display: flex;
  align-items: center;
  margin-left: auto;
}

/* Sign In and Get Started buttons */
.header-sign-in {
  padding: 10px 12px;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
}

.header-get-started {
  background-color: #267dff;
  color: white;
  padding: 10px 14px;
  font-size: 16px;
  border-radius: 4px;
  margin-left: 10px;
  transition: background-color 0.3s ease;
  min-width: 160px;
}

.header-get-started:hover {
  background-color: #1663d7;
}

/* User menu dropdown styles (Desktop) */
.user-menu {
  position: relative;
  display: inline-block;
}

.user-name-button {
  padding: 10px 40px;
  border: 1px solid #bababa;
  border-radius: 4px;
  font-family: 'Epilogue', sans-serif;
  font-weight: 600;
  background-color: white;
  margin-left: 120px;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #ccc;
  box-shadow: 0px 8px 16px rgba(0,0,0,0.2);
  z-index: 1;
  min-width: 160px;
}

.dropdown-menu button {
  background: none;
  border: none;
  padding: 12px 16px;
  width: 100%;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
}

.dropdown-menu button:hover {
  background-color: #f1f1f1;
}

.user-menu:hover .dropdown-menu {
  display: block;
}

/* Desktop-only and Mobile-only visibility */
.desktop-only {
  display: inline-block;
}

.mobile-only {
  display: none;
}

/* Mobile Menu & Hamburger hidden on larger screens */
@media (min-width: 433px) {
  .mobile-menu,
  .hamburger-menu {
    display: none !important;
  }
}

/* Additional responsive adjustments for medium screens */
@media (max-width: 1250px) {
  .header-button {
    font-size: 13px;
    margin: 0 8px;
  }
  
  .header-get-started,
  .header-sign-in {
    padding: 10px 12px;
  }
  
  .nav-links {
    flex-wrap: wrap;
    margin-top: 10px;
    gap: 20px;
    margin-left: 50px;
  }
}

/* Additional responsive adjustments for smaller screens */
@media (max-width: 960px) {
  .header {
    /* Default to column layout on smaller screens, but will be overridden at 432px */
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  
  .auth-buttons {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
  }
  
  .user-name-button {
    margin-left: 0;
  }
  
  .nav-links {
    justify-content: center;
    margin: 0;
  }
  
  .logo {
    margin-bottom: 5px;
  }
}

/* Mobile specific styles: override for screens up to 432px */
@media (max-width: 432px) {
  /* Hide desktop navigation and auth buttons */
  .nav-links,
  .auth-buttons {
    display: none;
  }
  
  /* Force a single row: logo on the left, hamburger on the right */
  .header {
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;
    padding: 10px 15px; /* Adjust padding to your liking */
  }
  
  .logo {
    font-size: 25px;
    margin-bottom: 0;
  }
  
  .hamburger-menu {
    display: block;
    font-size: 24px;
    cursor: pointer;
    background: none;
    border: none;
    color: #333;
  }
  
  /* Mobile Menu Dropdown */
  .mobile-menu {
    display: none;
    position: absolute;
    top: 60px;
    right: 10px;
    background: white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    flex-direction: column;
    align-items: flex-start;
    width: 200px;
    padding: 10px;
    z-index: 1001;
  }
  
  .mobile-menu.active {
    display: flex;
  }
  
  .mobile-menu button {
    width: 100%;
    text-align: left;
    padding: 10px;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 16px;
  }
  
  .mobile-menu button:hover {
    background-color: #f1f1f1;
  }
}

/* Keep .desktop-only hidden under 432px, show .mobile-only instead */
@media (max-width: 432px) {
  .desktop-only {
    display: none;
  }
  .mobile-only {
    display: block;
  }
}