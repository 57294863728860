/* Dashboard.css */

.dashboard-container {
    background-color: #fff;
    min-height: 100vh;
    padding: 20px;
  }
  
  .dashboard-content {
    max-width: 800px;
    margin-top: 80px;
    background: #fff;
    padding: 40px;
    border-radius: 8px;
  }
  
  .dashboard-content h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .dashboard-content p {
    font-size: 1.2rem;
    margin-bottom: 15px;
  }
  
  .dashboard-content ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .dashboard-content li {
    font-size: 1rem;
    margin-bottom: 5px;
  }
  