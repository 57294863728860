.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 650px;
  }
  
  .modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .modal-content h3 {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 400;
  }


  
  .cancel-button,
  .continue-button {
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cancel-button {
    background: #ccc;
    color: #333;
  }
  
  .cancel-button:hover {
    background: #999;
  }
  
  .continue-button {
    background: #007bff;
    color: white;
  }
  
  .continue-button:hover {
    background: #0056b3;
  }
  