/* HowItWorks.css */

.how-it-works {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.25rem; /* 20px -> 1.25rem */
  width: 76vw;
  height: 84vh;
  box-sizing: border-box;
  margin-top: 6.25rem; /* 100px -> 6.25rem */
}

.row {
  display: flex;
  width: 100%;
  gap: 1.875rem; /* 30px -> 1.875rem */
  margin: 0 0 0.7rem 0; /* 20px -> 1.25rem */
  flex: 1;

}

.box {
  display: flex;
  flex-direction: column; /* Stacks h3, h2, and p vertically */
  align-items: flex-start; /* Aligns text to the left */
  justify-content: flex-start;
  padding: 1.875rem 1.875rem 1.625rem 1.875rem; /* 30px -> 1.875rem, 10px -> 0.625rem */
  background-color: #f3f5fc;
  border: 0.0625rem solid #ccc; /* 1px -> 0.0625rem */
  font-size: 1.5rem; /* 24px -> 1.5rem */
  height: 90%;
  text-align: left; /* Ensures text alignment to the left */
  border: 0;
  border-radius: 0.5rem; /* 8px -> 0.5rem */
}

.box h3 {
  font-size: 1.625rem; /* 26px -> 1.625rem */
  margin: 0 0 0.3125rem; /* 5px -> 0.3125rem */
  padding: 0.375rem 0.5rem; /* 6px 8px -> 0.375rem 0.5rem */
  font-weight: 400;
  border-radius: 0.1875rem; /* 3px -> 0.1875rem */
  margin-bottom: 1rem; /* 16px -> 1rem */
}

.step-1-image, .step-2-image {
  position: absolute;
  top: 0;
  right: 0;
  width: 13rem; /* 230px -> 14.375rem */
  height: auto;
  border-radius: 0.25rem; /* 4px -> 0.25rem */
}

.step-1 {
  position: relative;
}

.step-2 {
  position: relative;
}

.step-1 h3 {
  background-color: #267dff;
  color: white;
}

.step-2 h3 {
  background-color: #f3e557;
  color: #1b1b1b;
}

.step-3 h3 {
  background-color: #9867ff;
  color: white;
}

.step-4 h3 {
  background-color: #00d085;
  color: white;
}

.step-5 h3 {
  background-color: #267dff;
  color: white;
}

.box h2 {
  font-size: 1.875rem; /* 30px -> 1.875rem */
  margin-bottom: 1.25rem; /* 20px -> 1.25rem */
  font-family: 'Epilogue', sans-serif;
  font-weight: 500;
}

.box p {
  font-size: 1.125rem; /* 18px -> 1.125rem */
  margin: 0;
  line-height: 1.2;
}

.half-width {
  width: 50%;
}

.third-width {
  width: 33.33%;
  margin-top: 0.4375rem; /* 7px -> 0.4375rem */
  margin-bottom: 1.25rem; /* 20px -> 1.25rem */
  padding: 1.875rem 1.875rem 0 1.875rem; /* Adjusted padding */
}

/* Responsive Styles */

/* Extra large screens */
@media (min-width: 106.25rem) { /* 1700px -> 106.25rem */
  .how-it-works {
    width: 80vw;
    gap: 1.25rem; /* 20px -> 1.25rem */
  }

  .row {
    gap: 2.5rem; /* 40px -> 2.5rem */
    margin: 0.625rem; /* 10px -> 0.625rem */
  }

  .box {
    padding: 1.875rem; /* Adjusted padding */
  }
}

@media (max-width: 93rem) { /* 1024px -> 64rem */

  .how-it-works {
    height: 88vh;
  }

}

@media (max-width: 84rem) { /* 1024px -> 64rem */

  .how-it-works {
    height: 94vh;
  }

}

@media (max-width: 76rem) { /* 1024px -> 64rem */

  .how-it-works {
    height: 105vh;
  }

}

/* Tablet view */
@media (max-width: 64rem) { /* 1024px -> 64rem */
  .how-it-works {
    width: 90vw;
    padding: 0.625rem; /* 10px -> 0.625rem */
  }

  .row {
    flex-direction: column;
    gap: 1.25rem; /* 20px -> 1.25rem */
  }

  .half-width, .third-width {
    width: 100%;
  }

  .box {
    padding: 1.5625rem; /* 25px -> 1.5625rem */
  }

  .box h3 {
    font-size: 1.5rem; /* 24px -> 1.5rem */
  }

  .box h2 {
    font-size: 1.75rem; /* 28px -> 1.75rem */
  }
}

/* Mobile view */
@media (max-width: 46.25rem) { /* 740px -> 46.25rem */
  .how-it-works {
    width: 95vw;
    padding: 0.3125rem; /* 5px -> 0.3125rem */
    margin-top: 6.875rem; /* 110px -> 6.875rem */
  }

  .row {
    flex-direction: column;
    gap: 0.9375rem; /* 15px -> 0.9375rem */
  }

  .box {
    padding: 0.9375rem; /* 15px -> 0.9375rem */
    margin-bottom: 0.625rem; /* 10px -> 0.625rem */
  }

  .box h3 {
    font-size: 1.375rem; /* 22px -> 1.375rem */
    margin-bottom: 0.625rem; /* 10px -> 0.625rem */
  }

  .box h2 {
    font-size: 1.5rem; /* 24px -> 1.5rem */
  }

  .box p {
    font-size: 1rem; /* 16px -> 1rem */
    line-height: 1.4;
  }

  .step-1-image, .step-2-image {
    width: 12.5rem; /* 200px -> 12.5rem */
  }
}

@media (max-width: 33.75rem) { /* 540px -> 33.75rem */
  .step-1-image, .step-2-image {
    width: 8.75rem; /* 140px -> 8.75rem */
  }
}

@media (max-width: 25.1875rem) { /* 355px -> 22.1875rem */

  .how-it-works {

    margin-top: 10rem; /* 110px -> 6.875rem */
  }

  .step-1-image, .step-2-image {
    width: 7.5rem; /* 120px -> 7.5rem */
  }
}

@media (min-width: 500px) and (max-width: 800px) and (min-height: 690px) {
  
  .row {
    gap: 0rem; /* 20px -> 1.25rem */
  }
  
  .step-1 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .step-2 {
    margin-bottom: 2rem;
  }

  .step-3 {
    margin-bottom: 2rem;
  }

  .step-4 {
    margin-bottom: 2rem;
  }

  .step-5 {
    margin-bottom: 2rem;
  }
}