/* Styles for the GenerateEmails container */
.generate-emails-container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 00px;
  text-align: center;
  margin-top: 210px
}

/* Welcome message styling */
.generate-emails-container h2 {
  font-size: 3.5rem;
  margin: 40px 0;
  color: #333;

}

.generate-emails-container h3 {
  font-size: 2rem;
  color: #333;
  margin: 40px 0;
  font-weight: 500;
}

/* Styling for the input label and field */
.generate-emails-container label {
  display: block;
  font-size: 1.2rem;
  color: #444;
}

.generate-emails-container input[type="url"] {
  width: 100%;
  padding: 10px;
  margin-top: 25px;
  border: 2px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 1rem;
}

/* Button styling */
.generate-emails-container button {
  display: inline-block;
  padding: 14px 20px;
  margin: 20px 5px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'Epilogue', sans-serif;
  font-weight: 600;
}

.generate-emails-container button:hover {
  background-color: #0056b3;
}

/* Feedback message styling */
.feedback-message {
  font-size: 1rem;
  color: #ff4d4d;
  margin-top: 15px;
}

.loading-message {
  margin: 20px 5px;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}


/* Circle loading spinner */
.loading-spinner {
margin: 10px auto;
border: 4px solid #ccc; /* Light gray */
border-top: 4px solid #007bff; /* Blue */
border-radius: 50%;
width: 40px;
height: 40px;
animation: spin 1s linear infinite;
}

.youtube-input-container input::placeholder {
text-align: center;
}

.youtube-input-container input {
text-align: center; /* Centers user input as well */
}

.error-message {
margin-top: 10px;
}

.progress-bar-container {
width: 100%;
height: 20px;
background: #e0e0e0;
border-radius: 10px;
overflow: hidden;
margin-top: 1rem;
}

.progress-bar {
height: 100%;
background: #007bff;
transition: width 0.5s linear;
}



/* Keyframes for spinner animation */
@keyframes spin {
0% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(360deg);
}
}


/* Responsive styles for widths smaller than 640px */
@media (max-width: 640px) {
.generate-emails-container h2 {
    font-size: 2rem;
}

.generate-emails-container h3 {
    font-size: 1.5rem;
    padding: 0 3%;
}

.generate-emails-container input[type="url"] {
    font-size: 0.9rem;
    padding: 8px;
    width: 90%;
}

.generate-emails-container button {
    padding: 10px 15px;
    font-size: 0.9rem;
}

.feedback-message {
    font-size: 0.9rem;
}

.generate-emails-container label {
  padding: 0 3%;

}
}