.insert-channels-container {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 20px;
  background-color: #f3f5fc;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
}

.insert-channels-header {
  text-align: center;
  margin-top: 80px;
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.insert-channels-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}

.insert-channels-columns {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}

.insert-channels-input-group {
  display: flex;
  align-items: center;
  justify-content: center;
}

.insert-channels-label {
  font-size: 16px;
  color: #555;
  margin-right: 10px;
  width: 30px;
  text-align: right;
}

.insert-channels-input {
  width: 300px;
  padding: 7px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.insert-channels-input.error-border {
  border: 1px solid red;
}

.insert-channels-button {
  background-color: #267dff;
  color: white;
  padding: 10px 15px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 200px;
  align-self: center;
  margin-top: 10px;
}

.insert-channels-button:hover {
  background-color: #1663d7;
}

.error-message {
  color: red;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}

.loading {
  text-align: center;
  font-size: 18px;
  color: #666;
}