.contact-page {
  background-color: #f3f5fc;
  width: 100%;
  height: 120vh;
  margin: 0;
  background-image: url("../public/grid-left.png"), url("../public/grid-right.png");
  background-position: left top 35vh, right top 20.625vh; /* Converted px to vh */
  background-repeat: no-repeat;
  background-size: auto 40%;
  font-family: 'Epilogue', sans-serif; /* Default font for the page */
}

.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.contact-h2 {
  text-align: center;
  max-width: 60rem; /* 960px -> 60rem */
  margin-top: 8rem; /* 200px -> 12.5rem */
  margin-bottom: 0rem; /* 20px -> 1.25rem */
  font-size: 4.125rem; /* 66px -> 4.125rem */
  color: #333;
  font-family: 'Questrial', sans-serif; /* Font for <h2> only */
}

.contact-p {
  text-align: center;
  max-width: 60rem; /* 960px -> 60rem */
  font-size: 1.375rem; /* 22px -> 1.375rem */
  margin: 0 0 2.5rem 0; /* 40px -> 2.5rem */
  color: #666;
  padding: 0 20%;
}

.contact-form-container {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 37.5rem; /* 600px -> 37.5rem */
}

.contact-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  border: 0.09375rem solid #343434; /* 1.5px -> 0.09375rem */
  border-radius: 0.5rem; /* 8px -> 0.5rem */
  padding: 1.875rem; /* 30px -> 1.875rem */
  box-shadow: 0.3125rem 0.3125rem; /* 5px 5px -> 0.3125rem 0.3125rem */
}

.contact-form label {
  font-size: 1rem; /* 16px -> 1rem */
  color: #333;
  margin-bottom: 0.5rem; /* 8px -> 0.5rem */
}

.contact-form input,
.contact-form textarea {
  font-size: 1rem; /* 16px -> 1rem */
  padding: 0.625rem; /* 10px -> 0.625rem */
  border: 0.0625rem solid #ddd; /* 1px -> 0.0625rem */
  border-radius: 0.3125rem; /* 5px -> 0.3125rem */
  margin-bottom: 1.25rem; /* 20px -> 1.25rem */
  width: 100%;
}

/* Ensure consistent font for all placeholders */
.contact-form input::placeholder,
.contact-form textarea::placeholder {
  font-family: 'Epilogue', sans-serif;
  font-size: 1rem; /* 16px -> 1rem */
  color: #aaa; /* Optional: Adjust color for better visibility */
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-color: #007bff;
  outline: none;
}

.submit-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.8125rem 0; /* 13px -> 0.8125rem */
  font-size: 1rem; /* 16px -> 1rem */
  border-radius: 0.5rem; /* 8px -> 0.5rem */
  cursor: pointer;
  font-weight: 700;
}

.submit-button:hover {
  background-color: #1663d7;
}

.alternate-contact {
  margin-top: 2.5rem; /* 40px -> 2.5rem */
  font-size: 1rem; /* 16px -> 1rem */
  color: #666;
  text-align: center;
}

.alternate-contact a {
  color: #007bff;
  text-decoration: none;
}

.alternate-contact a:hover {
  text-decoration: underline;
}

/* Large screens (above 1500px) */
@media (min-width: 100rem) { /* 1500px -> 93.75rem */
  .contact-h2 {
    font-size: rem; /* 80px -> 5rem */
    margin-top: 10.625rem; /* 250px -> 15.625rem */
  }

  .contact-p {
    font-size: 1.625rem; /* 26px -> 1.625rem */
    padding: 0 15%;
  }

  .contact-form-container {
    max-width: 50rem; /* 800px -> 50rem */
  }

  .contact-form {
    padding: 2.5rem; /* 40px -> 2.5rem */
  }
}

/* Small screens (below 870px) */
@media (max-width: 54.375rem) { /* 870px -> 54.375rem */
  .contact-h2 {
    font-size: 3rem; /* 48px -> 3rem */
    margin-top: 22vh; /* Adjust spacing */
  }

  .contact-p {
    font-size: 1.125rem; /* 18px -> 1.125rem */
    padding: 0 10%;
  }

  .contact-form-container {
    max-width: 80%;
    padding: 0 1.25rem; /* 20px -> 1.25rem */
  }

  .contact-form {
    padding: 1.25rem; /* 20px -> 1.25rem */
  }

  .submit-button {
    font-size: 0.875rem; /* 14px -> 0.875rem */
    padding: 0.625rem 0; /* 10px -> 0.625rem */
  }

  .alternate-contact {
    font-size: 0.875rem; /* 14px -> 0.875rem */
    margin-top: 1.25rem; /* 20px -> 1.25rem */
  }
}

@media (max-width: 25rem) { /* 870px -> 54.375rem */
  .contact-h2 {
    font-size: 3rem; /* 48px -> 3rem */
    margin-top: 26vh; /* Adjust spacing */
  }
}


@media (max-width: 17.5rem) { /* 870px -> 54.375rem */
  .contact-h2 {
    font-size: 2.5rem; /* 48px -> 3rem */
    margin-top:32vh; /* Adjust spacing */
  }
}