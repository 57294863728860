.reset-password-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  box-sizing: border-box;
}

.reset-password-page h2 {
  font-size: 48px;
  font-weight: 900;
  margin: 120px 0 20px 0;
  text-align: center;
  font-family: 'Questerial', sans-serif;
}

.reset-password-page input {
  padding: 12px;
  border: 1px solid #292929;
  border-radius: 10px;
  font-size: 16px;
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;
  color: #000;
  text-align: center;
  margin-top: 10px;
  line-height: 1.5;
}

.reset-password-page input::placeholder {
  text-align: center;
}

.reset-password-page button {
  width: 100%;
  max-width: 400px;
  padding: 12px 0;
  background-color: #267dff;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  font-family: 'Epilogue', sans-serif;
  margin-top: 20px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.reset-password-page button:hover {
  background-color: #0056b3;
}

.error-text {
  color: red;
  font-size: 14px;
  margin-top: 5px;
  text-align: center;
}

.error-text {
  color: red;
  font-size: 0.9rem;
  margin-top: 5px;
}

.success-text {
  color: green;
  font-size: 0.9rem;
  margin-top: 5px;
}

.input-error {
  border-color: red;
}


/* Responsive Styles */

/* Mobile view */
@media (max-width: 768px) {
  .reset-password-page h2 {
    font-size: 40px;
  }

  .reset-password-page input {
    padding: 10px;
    font-size: 14px;
  }

  .reset-password-page button {
    font-size: 16px;
    padding: 10px 0;
  }
}

/* Extra small mobile view */
@media (max-width: 480px) {
  .reset-password-page h2 {
    font-size: 32px;
  }

  .reset-password-page input {
    padding: 8px;
    font-size: 12px;
  }

  .reset-password-page button {
    font-size: 14px;
    padding: 8px 0;
  }
}
