* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


html, body {
  height: 100%;
  margin: 0;
  font-family: 'Epilogue', sans-serif;
  background-color: white;
  display: flex;
  justify-content: center; /* Ensure horizontal centering of content */
  align-items: flex-start; /* Start aligning content from the top */
}

/* Sticky Header */


/* Main container */
.homepage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 75rem; /* 1200px -> 75rem */
}

/* Styling for individual sections */
.intro-section {
  width: 100%;
}

.features-section {
  width: 100%;
  padding: 0;
}

/* Adjustments for wide screens */
@media (min-width: 1700px) {
  .homepage-container {
    padding-top: 15vh; /* Add more space for header on larger screens */
  }
}

@media (max-width: 1030px) {
  .homepage-container {
    padding-top: 0vh; /* Adjust space for smaller screens */
  }
}

/* Adjustments for narrow screens */
@media (max-width: 550px) {
  .homepage-container {
    padding-top: 0vh; /* Add more space for smaller screens */
  }
}

@media (max-width: 410px) {
  .homepage-container {
    padding-top: 10vh; /* Add extra space for very small screens */
  }


}

@media (min-width: 500px) and (max-width: 800px) and (min-height: 690px) {
  .homepage-container {
    gap: 40px; /* Adjust spacing between sections */
  }

  intro-section {
    margin-top: 150px; /* Adjust to keep proper spacing for intro */
  }
}

@media (min-width: 900px) and (max-width: 1500px) and (min-height: 900px) {
  .homepage-container {
    gap: 40px; /* Adjust spacing between sections */
  }

  .intro-section {
    margin-top: 700px; /* Adjust to keep proper spacing for intro */
  }
}