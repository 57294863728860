.settings-outer-container {
  padding: 20px;
  background-color: #fff;
}

.settings-container {
  max-width: 1200px;
  margin: 0 auto;
  background: #fff;
  padding: 30px;
  border-radius: 10px;
  /* border: 1px solid #e0e0e0;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
  margin-top: 100px;
}

.settings-h2 {
  font-size: 36px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 20px;
  font-family: 'Questrial', sans-serif; /* Font for <h2> only */
}

.settings-content {
  display: flex;
  flex-direction: column; /* Stack sections vertically */
  gap: 0px;
}

.settings-left,
.settings-right {
  flex: 1;
  width: 100%; /* Ensure each section takes full width */
}

.details-section,
.subscription-section,
.settings-right {
  margin-bottom: 30px;
}

.channels-form {
  display: grid;
  gap: 10px;
}

.channels-form[style] {
  /* Grid layout for dynamic columns */
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}

.settings-input {
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.settings-button {
  padding: 10px 20px;
  font-size: 14px;
  background-color: #333;
  border: none;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
  margin-top: 15px;
  margin-right: 15px;
}

.settings-button:hover {
  background-color: #202020;
}

.input-section h4 {
  margin: 30px 0 7px 0;
  font-weight: 600;
}

.details-section h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0px;
}

.subscription-section h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
}

.settings-right h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 0;
}

.success-message {
  color: green;
  margin-top: 10px;
  font-size: 14px;
  text-align: left;
}