/* IntroSection.css - Responsive Styling Updates */

.intro-section {
  background-color: white;
  margin-top: 7.5rem; /* 120px -> 7.5rem (16px base) */
  padding: 0 1.25rem; /* 20px -> 1.25rem */
}

/* General Styling for Intro Section */
.illustration-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Crops excess content */
}

.lottie-wrapper {
  width: auto; /* Maintain original width */
  height: 250px; /* Adjust to crop top and bottom */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lottie-player {
  width: 80%; /* Reduce size proportionally */
  height: auto; /* Keep aspect ratio */
}







.illustration {
  max-width: 38.75rem; /* 620px -> 38.75rem */
  width: 100%;
  height: auto;
}

/* Content Section */
.homepage-h2 {
  font-size: 4rem; /* 64px -> 4rem */
  margin-top: 0;
  color: #2e2e2e;
  margin-bottom: 1.875rem; /* 30px -> 1.875rem */
  padding: 0 15%;
  font-weight: 600;
}

.intro-text {
  font-size: 20px; /* 22px -> 1.375rem */
  color: #2e2e2e;
  margin: 0;
  padding: 0 25%;
  font-weight: 400;
  line-height: 27px;
}

/* CTA Section */
.cta-section {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2.0rem; /* 40px -> 2.5rem */
  gap: 0.625rem; /* 10px -> 0.625rem */
}

.cta-button {
  background-color: #267dff;
  color: white;
  border: none;
  padding: 1rem 1.5rem; /* 16px 24px -> 1rem 1.5rem */
  font-size: 1.125rem; /* 18px -> 1.125rem */
  border-radius: 0.5rem; /* 8px -> 0.5rem */
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'Epilogue', sans-serif;
  font-weight: 600;
}

.cta-button:hover {
  background-color: #1663d7;
}

/* Responsive Styles */

@media (max-width: 1024px) {
  .homepage-h2 {
      font-size: 3rem; /* 48px -> 3rem */
      padding: 0 10%;
  }

  .intro-text {
      font-size: 1.25rem; /* 20px -> 1.25rem */
      padding: 0 10%;
  }
}

@media (max-width: 768px) {
  .homepage-h2 {
      font-size: 2.25rem; /* 36px -> 2.25rem */
      padding: 0 10%;
  }

  .intro-text {
      font-size: 1.125rem; /* 18px -> 1.125rem */
      padding: 0 15%;
  }

  .cta-button {
      font-size: 1rem; /* 16px -> 1rem */
      padding: 0.875rem 1.25rem; /* 14px 20px -> 0.875rem 1.25rem */
  }

  .illustration {
    max-width: 90%;
    width: auto;
  }
}

@media (max-width: 650px) {
  .homepage-h2 {
      font-size: 2.375rem; /* 38px -> 2.375rem */
      padding: 0 5%;
  }

  .intro-text {
      font-size: 1.125rem; /* 18px -> 1.125rem */
      padding: 0 5%;
  }

  .cta-section {
      margin-top: 1.875rem; /* 30px -> 1.875rem */
  }

  .cta-button {
      font-size: 1rem; /* 16px -> 1rem */
      padding: 0.75rem 1rem; /* 12px 16px -> 0.75rem 1rem */
  }

  .illustration {
      max-width: 80%;
      width: auto;
  }
}


@media (max-width: 450px) {

  .intro-section {
      margin-top: 25vh; /* 80px -> 5rem */
  }
}


@media (max-width: 432px) {



  .homepage-h2 {
      font-size: 2.0rem; /* 34px -> 2.125rem */
      padding: 0 2%;
      font-weight: 500;
      margin-top: -10px;
  }

  .intro-text {
      font-size: 1rem; /* 16px -> 1rem */
      padding: 0 5%;
  }

  .cta-button {
      font-size: 0.875rem; /* 14px -> 0.875rem */
      padding: 1rem 1rem; /* 8px 12px -> 0.5rem 0.75rem */
  }

  .illustration {
      max-width: 85%;
      width: auto;
  }

  /* General Styling for Intro Section */
.illustration-container {
  margin-top: -150px;

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Crops excess content */
}

.lottie-wrapper {
  width: auto;
  height: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -20px; /* Moves it left */
}

.lottie-player {
  width: 110%;
  height: auto;
  transform: translateX(-10px); /* Moves the Lottie animation left */
}
}
